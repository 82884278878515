<template>
  <div>
    <material-card
      color="general"
      :title="$t('Driver.title')"
      :text="$t('Driver.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-car
            </v-icon>
            {{ $t("Driver.subtitle") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form 
              ref="editDriverForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex>
                    <v-text-field
                      v-if="editedItem?.user"
                      v-model="editedItem.user.fullName"
                      :label="$t('Driver.driver_name')"
                      :disabled="true"
                    />
                    <v-text-field
                      v-if="editedItem?.user"
                      v-model="editedItem.user.phone"
                      :label="$t('Common.phone_number')"
                      :disabled="true"
                    />
                    <v-textarea
                      v-model="editedItem.note"
                      :label="$t('Driver.driver_note')"
                    />
                    <v-menu
                      ref="menu_start"
                      v-model="menu_start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.break_start"
                          :label="$t('Driver.break_start')"
                          prepend-icon="access_time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menu_start"
                        v-model="editedItem.break_start"
                        full-width
                        format="24hr"
                        :max="editedItem.break_end"
                        @click:minute="$refs.menu_start.save(time)"
                      />
                    </v-menu>
                    <!-- End time -->
                    <v-menu
                      ref="menu_end"
                      v-model="menu_end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.break_end"
                          :label="$t('Driver.break_end')"
                          prepend-icon="access_time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menu_end"
                        v-model="editedItem.break_end"
                        full-width
                        format="24hr"
                        :min="editedItem.break_start"
                        @click:minute="$refs.menu_end.save(time)"
                      />
                    </v-menu>
                    <v-subheader class="delivery-custom-subheader">
                      {{ $t('Driver.delivery_duration', { minutes: editedItem.delivery_duration || 0 }) }}
                    </v-subheader>
                    <v-slider
                      v-model="editedItem.delivery_duration"
                      :min="0"
                      :max="30"
                      :step="1"
                      class="delivery-custom-slider"
                      thumb-label
                    />
                  </v-flex>
                </v-layout>
              </v-container>
              <v-alert
                v-if="alertTextEdit"
                dense
                type="error"
              >
                {{ alertTextEdit }}
              </v-alert>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="driverList ? driverList : []"
          :options.sync="paginationOptions"
          :server-items-length="(driverListMeta?.itemCount) ? driverListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              title=""
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPopup from "../../components/DefaultPopup.vue";
import validationMixin from "../../components/helper/validationMixin";

export default {
  components: {
    DefaultPopup,
  },

  mixins: [validationMixin],

  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      driver_name: "",
      phone_number: "",
      note: "",
      break_start: "",
      break_end: "",
      user_id: "",
      user: {},
      delivery_duration: 5,
    },
    defaultItem: {
      isValid: true,
      driver_name: "",
      phone_number: "",
      note: "",
      break_start: "",
      break_end: "",
      user_id: "",
      user: {},
      delivery_duration: 5,
    },
    time: null,
    menu_start: false,
    menu_end: false,
    alertTextEdit: "",
    alertTextDelete: "",
    isSaving: false,
    // table:
    paginationCount: 0,
    paginationLoading: false,
    paginationOptions: {},
  }),

  computed: {
    ...mapGetters("driver", ["driverList", "driverListMeta"]),
    headers() {
      const headers = [
        { text: this.$t("Driver.driver_name"), value: "user.fullName" },
        { text: this.$t("Common.phone_number"), value: "user.phone" },
        { text: this.$t("Common.note"), value: "note" },
        { text: this.$t("Common.actions"), value: "actions", sortable: false },
      ];

      if(this.$store.state.role === "SUPER_ADMIN") {
        headers.splice(3, 0,  { text: this.$t("Common.company"), value: "company.company_name" });
      }

      return headers;
    },
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      if (val) this.editedItem.isValid = true;
      if (!val) this.close();
    },
    paginationOptions: {
      handler() {
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearDriverState();
  },

  methods: {
    ...mapActions("driver", [
      "getDriverList",
      "createDriver",
      "updateDriver",
      "deleteDriver",
      "clearDriverState",
    ]),
    

    async initialize() {
      this.paginationLoading = true;
      this.paginationOptions.role = sessionStorage.getItem("role");
      if (this.paginationOptions.sortBy[0] === 'user.fullName') this.paginationOptions.sortBy[0] = 'user.firstName'
      await this.getDriverList(this.paginationOptions);
      this.paginationLoading = false;
    },

    editItem(item) {
      this.editedIndex = this.driverList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (!this.editedItem?.delivery_duration) {
        this.editedItem.delivery_duration = this.defaultItem.delivery_duration
      } else {
        this.editedItem.delivery_duration = this.editedItem.delivery_duration / 60
      }
      this.dialog = true;
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteDriver(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Driver.alerts.error-delete");
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateDriver(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Driver.alerts.error-edit");
        }
      }
      this.isSaving = false;
    },
  },
};
</script>

<style  lang="scss">
  .delivery-custom-slider .v-input__slot {
    flex-direction: column;
    .v-label {
      width: -webkit-fill-available;
    }
    .v-slider {
      width: -webkit-fill-available;
    }
  }

  .delivery-custom-subheader {
    padding: unset;
  }
</style>
